import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import HeaderDesktop from "../components/headerDesktop"
import HeaderMobile from "../components/headerMobile"
import Footer from "../components/footer"
import BannerPageComponent from "../components/sections/bannerPage"

export default function Acupression() {
  return (
    <div className="irn-wrapper">
      <div className="irn-wrapper-inner-page">
        <HeaderDesktop />
        <HeaderMobile />
        <BannerPageComponent title="Acupression"/>
      </div>
      <div className="irn-content-page">
          <div className="irn-content-page_block bold">
            <h3>Qu'est-ce que c'est ?</h3>
            <p>
            L’acupression est une technique incroyable qui s’est imposée à moi lors d’une journée<br/>« découverte du Shiatsu »,  technique que j’ai souhaité approfondir par la suite.<br/>J’étais absolument persuadée que le binôme acupression/hypnose serait un formidable allié dans le processus thérapeutique … et je ne pensais pas qu’il dépasserait à ce point mes espérances !!!! 
            </p>
            <p>
            L’acupression que vous connaissez peut-être mieux sous les termes de « Digitopuncture » ou « réflexologie » est issue de la Médecine Traditionnelle Chinoise. Elle vise à rétablir la circulation énergétique dans le corps.
            </p>
            <p>L’énergie circule dans le corps par l’intermédiaire de voies de circulation appelées « Méridiens ». Les chocs émotionnels, les épreuves de la vie vont créer des sortes de nœuds qui vont empêcher l’énergie de circuler librement.</p>
            <p>C’est là que j’interviens, en exerçant des pressions sur les points d’acupuncture de différents méridiens. Je vous conseille d’ailleurs de choisir une tenue confortable pour vous sentir complètement à votre aise durant la séance.</p>
            
            <p>Chaque pression mécanique, vous le constaterez par vous-même,  a un impact sur le psychique.</p>
            
            <p>Cette première séance est une approche qui nous permettra de faire connaissance et d’instaurer entre vous et moi une confiance qui facilitera grandement la visée de notre deuxième séance dédiée à l’hypnose thérapeutique (si vous choisissez ce type de séance).</p>
            <p>J’insiste sur le terme de confiance, fondamentale dans le processus thérapeutique, entre la personne qui pratique et celle qui reçoit.</p>
            <p><span className="souligne">L’effet Rebond</span> : A l’issue d’une séance d’acupression  vous vous trouverez en parfaite harmonie avec votre corps, votre esprit… que du bonheur !!! Favorisez également le repos et hydratez-vous !! Seulement le lendemain de la séance il est possible que vous subissiez ce que l’on appelle «l’effet rebond». Vous pouvez ressentir des courbatures, comme si vous veniez d’effectuer une séance intensive de sport, des maux de tête, un mal-être… bref et là vous penserez à moi et vous me contacterez peut-être en me disant «et bien merci beaucoup, je me sens encore plus mal qu’avant !!» et je vous répondrai «J’en suis ravie !! Tout est absolument normal et cela disparaitra en quelques jours !!<br/>Cela prouve que la séance a été efficace !!»<br/>L’organisme entre dans un processus de réparation. </p>
            <p>Cela ne sera pas le cas à chaque séance seulement les toutes premières ou peut-être pas du tout.</p>
            <p>Enfin, l’acupression  est aussi très efficace pour réduire les effets secondaires de la chimiothérapie : nausées – perte d’appétit –  paresthésie (troubles de la sensibilité au niveau des pieds et des mains) mais aussi les problèmes de sommeil liés au stress d’une éventuelle évolution de la maladie. </p>
            <p>Je tiens à préciser qu’en aucun cas elle ne se substituera à un traitement médical,<br/>elle lui viendra tout naturellement en appui.</p>
          </div>
      </div>
      <Footer />
    </div>
    )
}
